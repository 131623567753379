import {CommonModule} from '@angular/common';
import {NgModule, Type} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from './modules/material.module';
import {SharedPipesModule} from './pipes/pipes.module';
import {IconModule} from './modules/icon.module';
import {StatusComponent} from './components/status/status.component';
import {TaskProgressComponent} from './components/task-progress/task-progress.component';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {RecipientInfoComponent} from './components/recipient-info/recipient-info.component';
import {DndProgressComponent} from './components/dnd-progress/dnd-progress.component';
import {UploadComponent} from './components/upload/upload.component';
import {LabelValueComponent} from './components/label-value/label-value.component';
import {EditLinkComponent} from './components/edit-link/edit-link.component';
import {QuantitySelectorComponent} from './components/quantity-selector/quantity-selector.component';
import {RemoveFiltersComponent} from './components/remove-filters/remove-filters.component';
import {LoaderDirectiveModule} from '@app/directives/loader/loader.module';
import {ImageStateDirectiveModule} from '@app/directives/image-state/image-state.module';
import {DndDirectiveModule} from '@app/directives/dnd/dnd.module';
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {ThemeSelectorComponent} from './components/theme-selector/theme-selector.component';
import {LogoComponent} from './components/logo/logo.component';
import {AwesomeIconsModule} from './awesome-icons.module';
import {NumberInputDirectiveModule} from '@app/directives/number-input/number-input.module';
import {ClickableTooltipDirective} from './directives/clickable-tooltip.directive';
import {TableColumnSelectorComponent} from './components/table-column-selector/table-column-selector.component';
import {HistoryOperationsDialogComponent} from './components/history-operations-dialog';
import {ClipboardModule} from '@angular/cdk/clipboard';

export const SHARED_COMPONENTS: Type<any>[] = [
  PaginatorComponent,
  StatusComponent,
  TaskProgressComponent,
  RecipientInfoComponent,
  DndProgressComponent,
  UploadComponent,
  LabelValueComponent,
  EditLinkComponent,
  QuantitySelectorComponent,
  RemoveFiltersComponent,
  LanguageSelectorComponent,
  ThemeSelectorComponent,
  LogoComponent,
  TableColumnSelectorComponent,
  HistoryOperationsDialogComponent,
];
export const SHARED_DIRECTIVES_MODULES: Array<Type<any>> = [
  LoaderDirectiveModule,
  ImageStateDirectiveModule,
  DndDirectiveModule,
  NumberInputDirectiveModule,
];
export const SHARED_DIRECTIVES: Type<unknown>[] = [ClickableTooltipDirective];

@NgModule({
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  imports: [
    CommonModule,
    SharedPipesModule,
    IconModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AwesomeIconsModule,
    ClipboardModule,
    ...SHARED_DIRECTIVES_MODULES,
  ],
  exports: [
    CommonModule,
    SharedPipesModule,
    IconModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AwesomeIconsModule,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES_MODULES,
    ...SHARED_DIRECTIVES,
  ],
})
export class SharedModule {}
