import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly mobileWidth = 500;
  private readonly tabletWidth = 1000;
  private readonly customWidth = 1200;

  isMobile$ = new BehaviorSubject<boolean>(window.innerWidth < this.mobileWidth);
  isTablet$ = new BehaviorSubject<boolean>(window.innerWidth < this.tabletWidth);
  isCustomWidth$ = new BehaviorSubject<boolean>(window.innerWidth < this.customWidth);

  checkWidth() {
    const isMobile = window.innerWidth < this.mobileWidth;
    const isTablet = window.innerWidth < this.tabletWidth;
    const isCustomWidth = window.innerWidth < this.customWidth;
    if (isMobile != this.isMobile$.value) {
      this.isMobile$.next(isMobile);
    }
    if (isTablet != this.isTablet$.value) {
      this.isTablet$.next(isTablet);
    }
    if (isCustomWidth != this.isCustomWidth$.value) {
      this.isCustomWidth$.next(isCustomWidth);
    }
  }
}
