export enum OrderState {
  draft = 'draft',
  approved = 'approved',
  fulfilled = 'fulfilled',

  sent = 'sent',
  received = 'received',

  awaiting_documents = 'awaiting_documents',
  cancelled = 'cancelled',
  declined = 'declined',

  recipient_unreachable = 'recipient_unreachable',
  recipient_refused_collecting = 'recipient_refused_collecting',
  recipient_failed_collecting = 'recipient_failed_collecting',
}
