import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, map, share} from 'rxjs';
import {Address} from '@core/models';
import {EntitiesService, PaginatedResults} from './entities.service';
import {HttpClient} from '@angular/common/http';
import {SnackBarService} from './snack-bar.service';

type AddressResponse = null | PaginatedResults<Address>;

@Injectable({
  providedIn: 'root',
})
export class AddressService extends EntitiesService {
  override readonly entityName = 'Address';
  override readonly entityPath = 'addresses';
  override readonly uiErrorHandler = this.snackBarService;

  addresses$ = new BehaviorSubject<Address[]>([]);

  constructor(
    protected override http: HttpClient,
    private snackBarService: SnackBarService,
  ) {
    super(http);
  }

  loadAddresses() {
    this.items<Address>().subscribe((resp) => {
      this.addresses$.next(
        resp?.results.map((x) => {
          return {...x, image: `/assets/icons/delivery/${x.delivery_method}.png`};
        }) || [],
      );
    });
  }

  editAddress(address: Address) {
    return this.put<Address>(`${address.id}`, address, 'Update user address');
  }

  deleteAddress(addressId: number) {
    return this.delete(`${addressId}`);
  }

  createAddress(address: Address) {
    return this.post<Address>('', address, 'Create address').pipe(
      map((data) => {
        if (data) {
          const addresses = this.addresses$.value;
          addresses.push({
            ...data,
            image: `/assets/icons/delivery/${data.delivery_method}.png`,
          });
          this.addresses$.next(addresses);
        }
      }),
    );
  }
}
