import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

const JWT_EXPIRATION = 5 * 24 * 60 * 60; // API token valid for 7 days
const JWT_EXPIRATION_MS = JWT_EXPIRATION * 1000;
const JWT_EXPIRATION_KEY = 'jwt_expires_at';
const JWT_TOKEN_KEY = 'jwtToken';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _token = '';

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  constructor() {
    this.token = this.getToken() || '';
  }

  public setSession(jwtToken: string): void {
    if (!jwtToken) return;
    this.token = jwtToken;
    const cookieExpiresAt = new Date(Date.now() + JWT_EXPIRATION_MS);
    document.cookie =
      '_drar_jwt=' +
      jwtToken +
      ';domain=' +
      environment.cookieDomain +
      ';path=/;secure;expires=' +
      cookieExpiresAt.toUTCString();
    localStorage.setItem(JWT_TOKEN_KEY, jwtToken);
    localStorage.setItem(JWT_EXPIRATION_KEY, JSON.stringify(cookieExpiresAt.valueOf()));
  }

  public removeSession(): void {
    this.token = '';
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(JWT_EXPIRATION_KEY);
    document.cookie =
      '_drar_jwt=;domain=' + environment.cookieDomain + ';path=/;secure;expires=Thu, 01 Jan 1970 00:00:00';
  }

  public isTokenInActualState(): boolean {
    const expiresAt = this.getExpiration();
    return expiresAt && new Date() < expiresAt;
  }

  public isExpiredToken(): boolean {
    return !this.isTokenInActualState();
  }

  public getToken(): string | null {
    const token = localStorage.getItem(JWT_TOKEN_KEY);
    let expiresAt = this.getExpiration();
    if (expiresAt)
      document.cookie =
        '_drar_jwt=' +
        token +
        ';domain=' +
        environment.cookieDomain +
        ';path=/;secure;expires=' +
        expiresAt.toUTCString();
    return token;
  }

  public getExpiration(): false | Date {
    const expiration = localStorage.getItem(JWT_EXPIRATION_KEY);
    if (!expiration) return false;
    const expiresAt = JSON.parse(expiration);
    return new Date(expiresAt);
  }
}
