import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  transform(
    value: number,
    locale: string = 'uk-UA',
    maximumFractionDigits: number = 0,
    minimumFractionDigits = 0,
  ): string {
    return value.toLocaleString(locale, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
}
