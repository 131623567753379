import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  scan,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import {ApiService} from '@services/api.service';
import {tap} from 'rxjs/operators';
import {FilterItem, FilterItemEntityType, FiltersMap, PaginationResponse} from '@core/models/common';
import {isEqual} from 'lodash';
import dayjs from 'dayjs';

export type FilterModifyFn = (item: FilterItem<FilterItemEntityType>) => FilterItem<FilterItemEntityType>;

export interface CustomParameter {
  name: string;
  value: string;
}

export type PaginatedResults<TResponse> = PaginationResponse<TResponse> & {activePages: number[]};

export abstract class EntitiesService extends ApiService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public page$ = new BehaviorSubject(1);
  public pageSize$ = new BehaviorSubject(12);
  public sort$ = new BehaviorSubject<string>('');
  public query$ = new BehaviorSubject<string>('');
  public relation$ = new BehaviorSubject<string>('');
  public order$ = new BehaviorSubject<string>('');
  public allFilters$ = new BehaviorSubject<FiltersMap>({});
  public selectedFilters$ = new BehaviorSubject<Record<string, string[]>>({});
  public state$ = new BehaviorSubject<string[]>([]);
  public customParameter$ = new BehaviorSubject<CustomParameter | null>(null);
  private forceReload$ = new BehaviorSubject(0);
  private loadMore$ = new Subject<void>();
  private dayStart$ = new BehaviorSubject<string>('');
  private dayEnd$ = new BehaviorSubject<string>('');

  public items<TResponseItem = unknown>(
    paramsOverride: Record<string, string[]> = {},
    path: string = '',
  ): Observable<PaginatedResults<TResponseItem> | null> {
    return combineLatest([this.allFilters$, this.forceReload$]).pipe(
      distinctUntilChanged(),
      debounceTime(50),
      switchMap((filters) => {
        if (null === filters) return of(null);

        return combineLatest([
          this.page$,
          this.pageSize$,
          this.sort$,
          this.selectedFilters$,
          this.query$,
          this.state$,
          this.relation$,
          this.order$,
          this.dayStart$,
          this.dayEnd$,
          this.customParameter$,
        ]).pipe(
          debounceTime(500),
          distinctUntilChanged((previous, current) => isEqual(previous, current)),
          switchMap(
            ([
              page,
              pageSize,
              sort,
              filters,
              query,
              state,
              relation,
              order_attached,
              dayStart,
              dayEnd,
              customParameter,
            ]) => {
              this.isLoading$.next(true);
              const params = this.prepareParams(
                page,
                pageSize,
                sort,
                query,
                filters,
                state,
                relation,
                order_attached,
                dayStart,
                dayEnd,
                customParameter,
                paramsOverride,
              );
              const initialPage = params['page'] ? parseInt(params['page'].toString()) : 1;

              return this.loadMore$.pipe(
                startWith(null),
                switchMap(() => {
                  return this.get<PaginationResponse<TResponseItem>>(path, params).pipe(
                    tap((data) => {
                      if (data) {
                        if (this.page$.value) {
                          if (params['page']) {
                            (<number>params['page'])++;
                          }
                        }
                        if (this.dayStart$.value) {
                          (<string>params['day_end']) = <string>params['day_start'];
                          (<string>params['day_start']) = dayjs(<string>params['day_start'])
                            .add(-1, 'month')
                            .format('YYYY-MM-DD');
                        }
                      }
                    }),
                  );
                }),
                scan(
                  (
                    acc: PaginationResponse<TResponseItem> | PaginatedResults<TResponseItem>,
                    data: PaginationResponse<TResponseItem>,
                    index: number,
                  ) => {
                    this.isLoading$.next(false);
                    return {
                      ...acc,
                      results: [...acc.results, ...(data?.results || [])],
                      total_pages: data?.total_pages,
                      total_count: data?.total_count,
                      total_quantity: data?.total_quantity,
                      activePages:
                        'activePages' in acc ? [...acc.activePages, initialPage + index] : [initialPage + index],
                    };
                  },
                  {
                    results: [] as TResponseItem[],
                    total_pages: 0,
                    total_count: 0,
                    total_quantity: 0,
                    activePages: [],
                  },
                ),
              );
            },
          ),
        );
      }),
    );
  }

  public setPage(page: number) {
    this.page$.next(page);
  }

  public setSort(sort: string) {
    this.sort$.next(sort);
  }

  public setSearch(query: string) {
    this.query$.next(query);
  }

  public setRelation(relation: string) {
    this.relation$.next(relation);
  }

  public setOrder(order_attached: string) {
    this.order$.next(order_attached);
  }

  public setState(state: string[]) {
    this.state$.next(state);
  }

  public setPageSize(size: number) {
    this.pageSize$.next(size);
  }

  public setDayStart(date: string) {
    this.dayStart$.next(date);
  }

  public setDayEnd(date: string) {
    this.dayEnd$.next(date);
  }

  public setCustomParameter(customParameter: CustomParameter | null) {
    this.customParameter$.next(customParameter);
  }

  public setSelectedFilters(filters: Record<string, string[]>) {
    this.selectedFilters$.next(filters);
  }

  public toggleFilter(filterName: string, key: string, state: boolean) {
    const filters = this.selectedFilters$.getValue();
    if (state) {
      if (!filters[filterName]) {
        filters[filterName] = [key];
      } else {
        filters[filterName].push(key);
      }
    } else {
      filters[filterName] = filters[filterName].filter((x) => x != key);
    }

    this.selectedFilters$.next(filters);
  }

  public resetItems() {
    this.selectedFilters$.next({});
    this.query$.next('');
    this.customParameter$.next(null);
    this.page$.next(1);
  }

  public reloadItems() {
    this.forceReload$.next(Math.random());
  }

  loadMore(): void {
    this.isLoading$.next(true);
    this.loadMore$.next();
  }

  protected initFilterItems(key: string, data: Record<string, any>, selectedFilters: any, modifyData?: FilterModifyFn) {
    const filterItems = (data && data[key]) || [];
    const selectedItems = (selectedFilters && selectedFilters[key]) || [];
    for (const filterItem of filterItems) {
      filterItem.isChecked = Array.isArray(selectedItems)
        ? selectedItems.includes(filterItem.item)
        : selectedItems === filterItem.item;

      if ('function' === typeof modifyData) {
        modifyData(filterItem);
      }
    }
    return filterItems;
  }

  private prepareParams(
    page: number,
    pageSize: number,
    sort: string,
    query: string,
    filters: Record<string, string[]>,
    state: string[],
    relation: string,
    order_attached: string,
    dayStart: string,
    dayEnd: string,
    customParameter: CustomParameter | null,
    paramsOverride: Record<string, string[]> = {},
  ): Record<string, number | string> {
    const params: Record<string, number | string> = {};

    if (dayStart) {
      params['day_start'] = dayStart;

      if (dayEnd) {
        params['day_end'] = dayEnd;
      }
    } else {
      params['page'] = page > 0 ? page : 1;

      if (pageSize) {
        params['per_page'] = pageSize;
      }
    }

    if (sort) {
      params['sort'] = sort;
    }

    if (query) {
      params['query'] = query;
    }

    if (state?.length) {
      params['state'] = state.join(',');
    }

    if (relation) {
      params['relation'] = relation;
    }

    if (order_attached) {
      params['order_attached'] = order_attached;
    }

    if (customParameter) {
      params[customParameter.name] = customParameter.value;
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length) {
          params[key] = value.join(',');
        }
      }
    });

    Object.entries(paramsOverride).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length) {
          params[key] = value.join(',');
        }
      }
    });

    return params;
  }
}
