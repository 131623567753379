import {CommonModule} from '@angular/common';
import {NgModule, PipeTransform, Type} from '@angular/core';
import {FormatDataTypePipe, KgWeightPipe, FileSizePipe, ProductImgPipe} from '.';
import {FormatNumberPipe} from './format-number.pipe';

const SHARED_PIPES: Type<PipeTransform>[] = [
  ProductImgPipe,
  FileSizePipe,
  KgWeightPipe,
  FormatDataTypePipe,
  FormatNumberPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...SHARED_PIPES],
  exports: [...SHARED_PIPES],
})
export class SharedPipesModule {}
