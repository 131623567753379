import {Pipe, PipeTransform} from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'formatDataType',
})
export class FormatDataTypePipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'number') {
      return value;
    }

    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    if (typeof value === 'string') {
      if (this.isDate(value) && value.length > 8) return dayjs(value).format('DD.MM.YYYY HH:mm');
      if (typeof Number(value) === 'number') return value;

      return value;
    }
  }

  private isDate(date: any) {
    return (new Date(date) as any) !== 'Invalid Date' && !isNaN(new Date(date) as any);
  }
}
