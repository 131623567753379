import {NgModule} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {MaterialModule} from './material.module';

@NgModule({
  imports: [MaterialModule],
})
export class IconModule {
  private path = 'assets/icons';

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon('nova-poshta', this.setPath(`${this.path}/nova-poshta.svg`));
    this.matIconRegistry.addSvgIcon('star', this.setPath(`${this.path}/star.svg`));
    this.matIconRegistry.addSvgIcon('feedback_icon', this.setPath(`${this.path}/feedback_icon.svg`));
    this.matIconRegistry.addSvgIcon('avatar', this.setPath(`${this.path}/avatar.svg`));
    this.matIconRegistry.addSvgIcon('avatar_soldier', this.setPath(`${this.path}/avatar_soldier.svg`));
    this.matIconRegistry.addSvgIcon('edit', this.setPath(`${this.path}/pencil-edit.svg`));
    this.matIconRegistry.addSvgIcon('moon', this.setPath(`${this.path}/theme-dark.svg`));
    this.matIconRegistry.addSvgIcon('sun', this.setPath(`${this.path}/theme-light.svg`));
    this.matIconRegistry.addSvgIcon('trash', this.setPath(`${this.path}/trash.svg`));
    this.matIconRegistry.addSvgIcon('check', this.setPath(`${this.path}/check.svg`));
    this.matIconRegistry.addSvgIcon('star-filled', this.setPath(`${this.path}/star-filled.svg`));
    this.matIconRegistry.addSvgIcon('star-outlined', this.setPath(`${this.path}/star-outlined.svg`));

    this.matIconRegistry.addSvgIcon('filament', this.setPath(`${this.path}/filament.svg`));
    this.matIconRegistry.addSvgIcon('production', this.setPath(`${this.path}/production.svg`));
    this.matIconRegistry.addSvgIcon('products', this.setPath(`${this.path}/products.svg`));
    this.matIconRegistry.addSvgIcon('custom-order', this.setPath(`${this.path}/custom-order.svg`));
    this.matIconRegistry.addSvgIcon('products-management', this.setPath(`${this.path}/products-management.svg`));
    this.matIconRegistry.addSvgIcon('requests', this.setPath(`${this.path}/requests.svg`));
    this.matIconRegistry.addSvgIcon('quality-check', this.setPath(`${this.path}/quality-check.svg`));
    this.matIconRegistry.addSvgIcon('other', this.setPath(`${this.path}/other_in_other.svg`));
    this.matIconRegistry.addSvgIcon('other_in_other', this.setPath(`${this.path}/other_in_other.svg`));
    this.matIconRegistry.addSvgIcon('drones_and_ammo', this.setPath(`${this.path}/drones_and_ammo.svg`));
    this.matIconRegistry.addSvgIcon('medical', this.setPath(`${this.path}/medical.svg`));
    this.matIconRegistry.addSvgIcon('energy', this.setPath(`${this.path}/energy.svg`));
    this.matIconRegistry.addSvgIcon('dummy', this.setPath(`${this.path}/dummy.svg`));
    this.matIconRegistry.addSvgIcon('infantry', this.setPath(`${this.path}/infantry.svg`));
    this.matIconRegistry.addSvgIcon('adapter', this.setPath(`${this.path}/adapter.svg`));
    this.matIconRegistry.addSvgIcon('connection_devices', this.setPath(`${this.path}/connection_devices.svg`));
    this.matIconRegistry.addSvgIcon('initiation_systems', this.setPath(`${this.path}/initiation_systems.svg`));
    this.matIconRegistry.addSvgIcon('ew_elint', this.setPath(`${this.path}/ew_elint.svg`));
    this.matIconRegistry.addSvgIcon('military-orders', this.setPath(`${this.path}/military-orders.svg`));
    this.matIconRegistry.addSvgIcon('orders', this.setPath(`${this.path}/orders.svg`));
    this.matIconRegistry.addSvgIcon('school', this.setPath(`${this.path}/school.svg`));
    this.matIconRegistry.addSvgIcon('documents', this.setPath(`${this.path}/documents.svg`));
    this.matIconRegistry.addSvgIcon('print-tasks', this.setPath(`${this.path}/print-tasks.svg`));
    this.matIconRegistry.addSvgIcon('my-tasks', this.setPath(`${this.path}/my-tasks.svg`));
    this.matIconRegistry.addSvgIcon('new', this.setPath(`${this.path}/new.svg`));
    this.matIconRegistry.addSvgIcon('reports', this.setPath(`${this.path}/reports.svg`));
    this.matIconRegistry.addSvgIcon('discussions', this.setPath(`${this.path}/discussions.svg`));
    this.matIconRegistry.addSvgIcon('statistic', this.setPath(`${this.path}/statistic.svg`));
    this.matIconRegistry.addSvgIcon('sapper', this.setPath(`${this.path}/sapper.svg`));
    this.matIconRegistry.addSvgIcon('drop_system', this.setPath(`${this.path}/drop_system.svg`));
    this.matIconRegistry.addSvgIcon('ammo', this.setPath(`${this.path}/ammo.svg`));
    this.matIconRegistry.addSvgIcon('visual', this.setPath(`${this.path}/visual.svg`));
    this.matIconRegistry.addSvgIcon('tail', this.setPath(`${this.path}/tail.svg`));
    this.matIconRegistry.addSvgIcon('diy', this.setPath(`${this.path}/diy.svg`));
    this.matIconRegistry.addSvgIcon('starlink', this.setPath(`${this.path}/starlink.svg`));
    this.matIconRegistry.addSvgIcon('sniper', this.setPath(`${this.path}/sniper.svg`));
    this.matIconRegistry.addSvgIcon('verification', this.setPath(`${this.path}/verification.svg`));
    this.matIconRegistry.addSvgIcon('in_progress', this.setPath(`${this.path}/in_progress.svg`));
    this.matIconRegistry.addSvgIcon('fire', this.setPath(`${this.path}/fire.svg`));
    this.matIconRegistry.addSvgIcon('pin', this.setPath(`${this.path}/pin.svg`));
    this.matIconRegistry.addSvgIcon('da_weight', this.setPath(`${this.path}/print/weight.svg`));
    this.matIconRegistry.addSvgIcon('da_copy', this.setPath(`${this.path}/copy.svg`));
    this.matIconRegistry.addSvgIcon('da_tag', this.setPath(`${this.path}/tag.svg`));
    this.matIconRegistry.addSvgIcon('da_without_sticker', this.setPath(`${this.path}/without_sticker.svg`));
    this.matIconRegistry.addSvgIcon('da_with_sticker', this.setPath(`${this.path}/with_sticker.svg`));
    this.matIconRegistry.addSvgIcon('da_add_sticker', this.setPath(`${this.path}/add_sticker.svg`));
    this.matIconRegistry.addSvgIcon('da_cart', this.setPath(`${this.path}/cart.svg`));
    this.matIconRegistry.addSvgIcon('da_search', this.setPath(`${this.path}/search.svg`));
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
