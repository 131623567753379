import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {heicTo} from 'heic-to';

interface PreviewFile {
  file: File;
  preview?: string | ArrayBuffer | null;
  inProgress: boolean;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  @Input() labelKey = 'checkout.order.load-document';
  @Input() mimeTypes?: string[];
  @Output() onchange = new EventEmitter<File[]>();

  items$ = new BehaviorSubject<PreviewFile[]>([]);

  // TODO Need to rewrite this smelly code
  // now using @ViewChild('docsUpload') private docsUpload!: UploadComponent;
  get files() {
    return this.items$.value.map((item) => item.file);
  }
  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.items$.next(this.items$.value.splice(index, 1));
    this.onchange.next(this.items$.value.map((x) => x.file));
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: File[]) {
    for (const file of files) {
      if (!this.mimeTypes || this.mimeTypes.includes(file.type)) {
        this.pushInSubject({file, preview: '-', inProgress: true});
        if (/\.hei(c|f)+$/.test(file.name.toLowerCase())) {
          const fileName = file.name.replace(/\.[^/.]+$/, '.jpeg');
          heicTo({
            blob: file,
            type: 'image/jpeg',
            quality: 0.5,
          }).then((data) => {
            const newFile = this.blobToFile(data as Blob, fileName);
            this.toBase64(newFile).then((base64) => {
              this.deleteFromSubject(file.name);
              this.pushInSubject({
                file: newFile,
                preview: base64,
                inProgress: false,
              });
              this.onchange.next(this.items$.value.map((x) => x.file));
            });
          });
        } else {
          this.toBase64(file).then((base64) => {
            this.pushInSubject({
              file,
              preview: base64,
              inProgress: false,
            });
            this.onchange.next(this.items$.value.map((x) => x.file));
          });
        }
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals: number = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private pushInSubject(file: PreviewFile) {
    this.items$.next([...this.items$.value.filter((x) => x.file.name != file.file.name), file]);
  }

  private deleteFromSubject(fileName: string) {
    this.items$.next(this.items$.value.filter((x) => x.file.name != fileName));
  }

  private toBase64(file: File): Promise<string | undefined> {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = (error) => reject(error);
      });
    } else {
      return Promise.resolve(undefined);
    }
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    const options: FilePropertyBag = {type: theBlob.type};
    return new File([theBlob], fileName, options);
  };
}
