<button
  *ngIf="pagesTotal > 1 && currentPage < pagesTotal && !activePages.includes(pagesTotal)"
  mat-button
  color="primary"
  class="paginatorHeader"
  (click)="showMore()"
>
  {{ 'paginator.show.more' | translate }}
</button>

<div class="paginator-selection" *ngIf="pagesTotal > 1 || currentPageSize()">
  <div class="pagination-buttons" *ngIf="pagesTotal > 1">
    <button mat-flat-button [disabled]="activePages.includes(1)" (click)="prev()">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <ng-container *ngFor="let page of getPagesToShow()">
      <button
        mat-flat-button
        [class.selectedPage]="page === currentPage"
        [disabled]="activePages.includes(page)"
        (click)="selectCurrentPage(page)"
      >
        {{ page }}
      </button>
    </ng-container>

    <button mat-flat-button [disabled]="activePages.includes(pagesTotal)" (click)="next()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

  <mat-form-field class="page-size" *ngIf="currentPageSize()">
    <mat-select [value]="currentPageSize()" (valueChange)="setPageSize($event)">
      <mat-option *ngFor="let pageSize of PAGE_SIZES" [value]="pageSize">
        {{ pageSize }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
