import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {environment} from '@env/environment';
import {PageNotFoundComponent} from '@app/pages/page-not-found/page-not-found.component';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {LoadingGuard} from '@core/guards/loading.guard';

const routes: Routes = [
  // auth pages
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoadingGuard],
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule),
    canActivate: [LoadingGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoadingGuard],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [LoadingGuard],
  },
  {
    path: 'inv',
    loadChildren: () => import('./pages/invite/invite.module').then((m) => m.InviteModule),
    canActivate: [LoadingGuard],
  },
  {
    path: 'users/sign_in',
    redirectTo: '',
  },
  {
    path: 'drucator',
    loadChildren: () => import('./pages/drucator/drucator.module').then((m) => m.DrucatorModule),
    canActivate: [AuthGuard],
  },

  // 404 page
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    canActivate: [LoadingGuard],
  },

  // 503 maintenance
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    canActivate: [LoadingGuard],
  },

  // discord link
  {
    path: 'discord-link',
    loadChildren: () => import('./pages/discord-link/discord-link.module').then((m) => m.DiscordLinkModule),
    canActivate: [AuthGuard],
  },

  // user pages
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/platform/platform.module').then((m) => m.PlatformModule),
  },

  {path: '**', redirectTo: 'page-not-found'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.debugRoutes,
      scrollPositionRestoration: 'enabled',
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
