import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {map, Observable} from 'rxjs';
import {HistoryOperation, HistoryOperationType} from '@core/models';

@Injectable()
export class HistoryOperationsService extends ApiService {
  override readonly entityName = 'HistoryOperation';
  override readonly entityPath = 'history_operations';

  getById(id: number, type: HistoryOperationType): Observable<HistoryOperation[]> {
    return this.get(`/${type}?record_id=${id}`).pipe(
      map((resp: any) =>
        resp.results
          .sort(
            (a: HistoryOperation, b: HistoryOperation) =>
              new Date(b.performed_at).getTime() - new Date(a.performed_at).getTime(),
          )
          .map((el: any) => {
            const diff = {...el.diff};
            delete diff.updated_at;
            return {
              ...el,
              diff,
            };
          }),
      ),
    );
  }
}
