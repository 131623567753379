import {SnackBarService} from './../../../services/snack-bar.service';
import {RouteItem} from './../../../core/enums/route.enum';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  protected readonly mimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  @Input() status: string = '';
  @Input() paymentSource?: string;
  @Output() upload = new EventEmitter<FileList>();

  RouteItem = RouteItem;

  constructor(
    private snackBarService: SnackBarService,
    private i18n: TranslateService,
  ) {}

  onUpload(event: Event) {
    event.stopPropagation();
  }

  onFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    const allowedExtensions = ['.png', '.jpg', '.jpeg', '.gif'];

    let invalidFiles = [];

    if (files?.length) {
      for (let i = 0; i < files!.length; i++) {
        const fileName = files[i].name.toLowerCase();
        if (!allowedExtensions.some((ext) => fileName.endsWith(ext))) {
          invalidFiles.push(files[i].name);
        }
      }

      if (invalidFiles.length > 0) {
        this.snackBarService.error(`${this.i18n.instant('upload.only_image_error')} ${invalidFiles.join(', ')}`);
      } else {
        this.upload.emit(files);
      }
    }
  }
}
