<div class="dialog">
  <button mat-icon-button class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="dialog-content" [loader]="isLoading()">
    <ng-container *ngIf="requests$ | async as requests">
      <h2>{{ 'history-operation.title' | translate }}</h2>
      <ng-container *ngIf="requests.length; else noData">
        <table mat-table [dataSource]="requests" class="mat-elevation-z8">
          <ng-container matColumnDef="performed_at">
            <th mat-header-cell *matHeaderCellDef>{{ 'history-operation.performed_at' | translate }}</th>
            <td mat-cell *matCellDef="let element" style="width: 130px">
              {{ element.performed_at | date: 'dd.MM.yyyy HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="performed_by.nickname">
            <th mat-header-cell *matHeaderCellDef>{{ 'history-operation.nickname' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="nickname-cell">
                <span
                  class="chip"
                  [cdkCopyToClipboard]="element.performed_by.code"
                  (cdkCopyToClipboardCopied)="copied()"
                  (click)="$event.stopPropagation()"
                >
                  {{ element.performed_by.code }}
                </span>

                <ng-container *ngIf="element.performed_by.telegram; else nickname">
                  <a [href]="'https://t.me/' + element.performed_by.telegram" target="_blank">
                    - {{ element.performed_by.nickname }}</a
                  >
                </ng-container>
                <ng-template #nickname>
                  <span> - {{ element.performed_by.nickname }}</span>
                </ng-template>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>{{ 'history-operation.event' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.event }}
            </td>
          </ng-container>

          <ng-container matColumnDef="diff">
            <th mat-header-cell *matHeaderCellDef>{{ 'history-operation.diff' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <table class="diff-table">
                <tbody>
                  <tr *ngFor="let item of element.diff | keyvalue" class="diff">
                    <td class="diff-table-key">{{ i18nkey + item.key | translate }}</td>
                    <td class="diff-table-old-value">{{ $any(item).value[0] | formatDataType }}</td>
                    <td class="diff-table-new-value">{{ $any(item).value[1] | formatDataType }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </ng-container>
    <ng-template #noData> NO DATA </ng-template>
  </div>
</div>
