import {ChangeDetectionStrategy, Component, EventEmitter, input, Input, output, Output} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
  currentPageSize = input<number | null>(null);

  pageSizeChange = output<number>();

  @Input({required: true}) pagesTotal = 1;
  @Input() currentPage = 1;
  @Input({required: true}) activePages: number[] = [];
  @Input() showLoadMore = false;

  @Output() currentPageChange = new EventEmitter<number>();
  @Output() loadMore = new EventEmitter<void>();

  protected readonly PAGE_SIZES = [12, 25, 50, 100];
  private readonly VIEW_SIZE = 7; // visible middle section

  get gap(): number {
    return Math.floor((this.VIEW_SIZE - 2) / 2) || 0;
  }

  selectCurrentPage(page: number) {
    this.currentPage = page;
    this.currentPageChange.emit(page);
  }

  prev() {
    this.selectCurrentPage(this.currentPage - 1);
  }

  next() {
    this.selectCurrentPage(this.currentPage + 1);
  }

  protected showMore(): void {
    this.loadMore.emit();
  }

  /**
   * Get pages to show. It always shows the first and the last page.
   * Then it takes the current page, and takes X pages before and after the current page to make sure that we have
   * `this.pagesTotal` pages in view.
   *
   * Known issues:
   * - if `this.viewSize` is even number, then it will show one more page than expected
   * - if `this.viewSize` is less or equal to 3 then it will show only the first, last and the current page
   *
   */
  protected getPagesToShow(): number[] {
    const pages = [1]; // always show the first page

    const realStart = 2;
    const gapRespectedStart = this.currentPage - this.gap;
    const startOverflow = Math.max(0, realStart - gapRespectedStart);

    const realEnd = this.pagesTotal - 1;
    const gapRespectedEnd = this.currentPage + this.gap;
    const endOverflow = Math.max(0, gapRespectedEnd - realEnd);

    const start = Math.max(realStart, gapRespectedStart - endOverflow);
    const end = Math.min(realEnd, gapRespectedEnd + startOverflow);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    pages.push(this.pagesTotal); // always show the last page
    return pages;
  }

  protected setPageSize(newPageSize: number): void {
    this.pageSizeChange.emit(newPageSize);
  }
}
