import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError, Observable, throwError} from 'rxjs';
import {environment} from '@env/environment';
import {SessionService} from '@services/session/session.service';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiCall = request.url.startsWith(environment.apiUrl);
    if (isApiCall) {
      const jwtToken = this.sessionService.getToken();
      if (jwtToken) {
        if (this.sessionService.isExpiredToken()) {
          console.warn('Token is expired');
        } else {
          request = request.clone({
            headers: request.headers.set('Authorization', jwtToken),
          });

          const v1 = request.url.match(
            /\/(profile|filament_types|articles|recruit|review_customer_request|review_curator_request|user_printers|printers|filament_sizes|printer_comments|order_review)/,
          );

          if (!v1) {
            request = request.clone({
              headers: request.headers.set('Accept', 'application/vnd.drar-v2+json'),
            });
          }

          request = request.clone({
            headers: request.headers.set(
              'Accept-Language',
              `${this.document.documentElement.lang};q=0.9,en-US,en;q=0.8`,
            ),
          });
        }
      } else {
        console.warn('Token is missing');
      }
    }

    return next.handle(request).pipe(
      catchError((err) => {
        console.log('err: ', err);
        if (err instanceof HttpErrorResponse && isApiCall) {
          switch (err.status) {
            case 401:
              this.sessionService.removeSession();
              this.router.navigate(['login']).catch();
              break;
            case 403:
              this.router.navigate(['']).catch();
              break;
          }
        }
        return throwError(err);
      }),
    );
  }
}
