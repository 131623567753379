import {Component, Inject, OnInit, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HistoryOperation, HistoryOperationType} from '@core/models';
import {TranslateService} from '@ngx-translate/core';
import {HistoryOperationsService} from '@services/history-operations.service';
import {SnackBarService} from '@services/snack-bar.service';
import {finalize, Observable} from 'rxjs';

@Component({
  selector: 'app-history-operations-dialog',
  templateUrl: './history-operations-dialog.component.html',
  styleUrls: ['./history-operations-dialog.component.scss'],
})
export class HistoryOperationsDialogComponent implements OnInit {
  isLoading = signal(false);
  i18nkey = '';
  requests$: Observable<HistoryOperation[]>;
  displayedColumns = ['performed_at', 'performed_by.nickname', 'event', 'diff'];

  constructor(
    public dialogRef: MatDialogRef<HistoryOperationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {id: number; type: HistoryOperationType},
    private historyOperationsService: HistoryOperationsService,
    private i18n: TranslateService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.loadData();
    this.set18nkey();
  }

  copied() {
    this.snackBarService.success(this.i18n.instant('copied'));
  }

  set18nkey() {
    this.i18nkey = `history-operation.${this.data.type}.`;
  }

  private loadData() {
    this.isLoading.set(true);
    this.requests$ = this.historyOperationsService.getById(this.data.id, this.data.type).pipe(
      finalize(() => {
        this.isLoading.set(false);
      }),
    );
  }
}
