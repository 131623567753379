import {Component, Inject, input, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';
import {DOCUMENT} from '@angular/common';
import {UserService} from '@services/user/user.service';

type Locale = {
  code: string;
  label: string;
  title: string;
};

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  fullName = input(false);
  readonly languageList: Locale[] = [
    {title: '🇺🇦', code: 'uk', label: 'Українська'},
    {title: '🇬🇧', code: 'en', label: 'English'},
  ];

  locale: Locale = this.languageList[0];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    const lang = localStorage.getItem('locale');
    if (localStorage.getItem('locale')) {
      this.locale = this.languageList.find((x) => x.code == lang) || this.languageList[0];
    }
    this.selectLocale(this.locale.code);
  }

  changeSiteLanguage(lang: string): void {
    this.locale = this.languageList.find((x) => x.code == lang)!;
    this.selectLocale(lang);
  }

  private selectLocale(localeCode: string) {
    const existLocale = this.languageList.find(({code}) => code === localeCode);
    if (existLocale) {
      this.translate.use(localeCode);
      localStorage.setItem('locale', localeCode);

      this.document.documentElement.lang = localeCode;

      dayjs.locale(localeCode);
      if (this.userService.user && this.userService.user.locale != localeCode) {
        this.userService.updateLang(localeCode).subscribe();
      }
    }
  }
}
