import {AddressService} from '@services/address.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User, UserPage, UserRole} from '@core/models';
import {BehaviorSubject, Observable, ReplaySubject, Subject, map, tap} from 'rxjs';
import {ApiService} from '@services/api.service';
import {SessionService} from '@services/session/session.service';
import {RouteItem} from '@core/enums';
import {environment} from '@env/environment';
import {PERMISSIONS} from '@core/constants';
import {SnackBarService} from '@services/snack-bar.service';

export type VerifyProvider = 'sms' | 'signal' | 'whatsapp';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  override readonly entityPath = 'profile';
  override readonly entityName = 'Profile';
  override readonly uiErrorHandler = this.snackBarService;

  private _user: User | null = null;
  public user$ = new ReplaySubject<User | null>(1);
  public userPages$ = new BehaviorSubject<UserPage[]>([]);
  public isInitialized$: Subject<boolean> = new Subject<boolean>();

  get user(): User | null {
    return this._user;
  }

  set user(value: User | null) {
    this._user = value;
  }

  get isUserCustomer$(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'customer' || role.name === 'developer')),
    );
  }

  get isUserNewPressman$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'new_pressman')));
  }

  get isUserPressman$(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'pressman' || role.name === 'new_pressman')),
    );
  }

  get isUserRegularPressman$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'pressman')));
  }

  get isUserDeveloper$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'developer')));
  }

  get isUserExpert$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'expert')));
  }

  get isUserUberCurator$(): Observable<boolean> {
    return this.user$.pipe(
      map(
        (user) => !!user?.roles?.some((role: UserRole) => ['assistant', 'admin', 'uber_curator'].includes(role.name)),
      ),
    );
  }

  get isUserCurator$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.roles?.some((role: UserRole) => role.name === 'curator')));
  }

  get isUserAssistant$(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => !!user?.roles?.some((role: UserRole) => ['assistant', 'admin'].includes(role.name))),
    );
  }

  get isUserStlAccess$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.roles?.some((role: UserRole) => ['stl_access'].includes(role.name))));
  }

  get homeUrl$(): Observable<string> {
    return this.user$.pipe(
      map((user) => {
        if (!user || user.roles.length === 0) {
          return RouteItem.PAGE_NOT_FOUND;
        }
        const roles = user.roles.filter((role: UserRole) => environment.roles.includes(role.name));
        return PERMISSIONS.find((item) => item.name === roles[0].name)?.homePage || RouteItem.PRODUCTS;
      }),
    );
  }

  constructor(
    private sessionService: SessionService,
    override http: HttpClient,
    private snackBarService: SnackBarService,
    private addressService: AddressService,
  ) {
    super(http);
  }

  public updateUser(user: User, updateAddress = true): void {
    if (user) {
      this.user = user;
      this.user$.next(user);
      this.userPages$.next(this.getMenuItems(user));
      this.isInitialized$.next(true);
      if (updateAddress) {
        this.addressService.loadAddresses();
      }
    }
  }

  public logout(): void {
    this.user = null;
    this.user$.next(null);
    this.isInitialized$.next(false);
    this.sessionService.removeSession();
    this.router.navigate(['/login']).catch();
  }

  public initUser(redirect = false, redirectUrl?: string): void {
    if (this.sessionService.token) {
      this.get<User>('').subscribe((user: User) => {
        this.updateUser({...user});
        this.hideGlobalLoader();
        if (redirect) {
          const roles = user.roles.filter((x: UserRole) => environment.roles.includes(x.name));
          const perm = PERMISSIONS.find((item) => item.name === roles[0].name);

          this.router.navigate([redirectUrl || perm?.homePage || '/']).catch();
        }
      });
    } else {
      this.hideGlobalLoader();
    }
  }

  public sendNewUserInformation(user: User): Observable<User> {
    return this.put<User>('', user).pipe(
      tap((res) => {
        this.updateUser(res, false);
      }),
    );
  }

  public requestVerificationCode(provider?: VerifyProvider): Observable<User> {
    return this.post<User>(`/profiles/request_otp`, {provider}, 'Send OTP', {root: true}).pipe(
      tap((data) => {
        if (data) {
          this.updateUser({...this.user!, phone_verification_sent: true});
        }
      }),
    );
  }

  public confirmVerificationCode(otp_secret: string): Observable<User> {
    return this.post<User>(`confirm_verification_code`, {otp_secret}).pipe(
      tap((data) => {
        if (data) {
          this.updateUser({...this.user!, phone_verified: true});
        }
      }),
    );
  }

  activateUserProfile(): Observable<void> {
    return this.put<void>('activate_public_profile', {}).pipe(
      tap(() => this.updateUser({...this.user!, public_profile: true})),
    );
  }

  deactivateUserProfile(): Observable<void> {
    return this.put<void>('deactivate_public_profile', {}).pipe(
      tap(() => this.updateUser({...this.user!, public_profile: false})),
    );
  }

  public isUserAdmin(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => role.name === 'admin');
  }

  public isUserCustomer(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => role.name === 'customer' || role.name === 'developer');
  }

  public isUserDeveloper(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => role.name === 'developer');
  }

  public isUserPressman(): boolean {
    if (!this.user) return false;
    return this.user.roles?.some((role: UserRole) => role.name === 'pressman');
  }

  public isUserCurator(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => role.name === 'curator');
  }

  public isUserUberCurator(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => role.name === 'uber_curator');
  }

  public isUserNewCustomer(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => role.name === 'new_customer');
  }

  get isUserNewCustomer$(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        return user.roles?.some((role: UserRole) => role.name === 'new_customer');
      }),
    );
  }

  public isUserAnyPressman(): boolean {
    if (!this.user) return false;
    return this.user?.roles?.some((role: UserRole) => ['pressman', 'new_pressman'].includes(role.name));
  }

  public switchRole(): Observable<void> {
    return this.put<void>('switch_role', {}).pipe(
      tap(() => {
        this.initUser(true);
      }),
    );
  }

  private hideGlobalLoader() {
    document.getElementById('intro')?.classList.add('hidden');
  }

  private getMenuItems(user: User): UserPage[] {
    const roles = user.roles;
    let menu: UserPage[] = [];
    const filterdRoles = roles.filter((x) => environment.roles.includes(x.name));
    filterdRoles.forEach((role) => {
      const roleData = PERMISSIONS.find((item) => item.name === role.name);
      if (roleData) {
        menu.push(...roleData.pages.filter((x) => !x.hideInMenu));
      }
    });
    return [...new Map(menu.map((item) => [item['name'], item])).values()];
  }
}
