import {RouteItem} from '@core/enums';
import {Permission} from '@core/models/permission.interface';

export const PERMISSIONS: Permission[] = [
  {
    name: 'customer',
    permissions: ['customer'],
    pages: [
      {
        name: 'nav.products',
        href: RouteItem.PRODUCTS,
        icon: 'products',
      },
      {
        name: 'nav.orders',
        href: RouteItem.ORDERS,
        icon: 'orders',
      },
      {
        name: 'nav.custom_order',
        href: RouteItem.CUSTOM_ORDER,
        icon: 'custom-order',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.documents',
        href: RouteItem.DOCUMENTS,
        icon: 'documents',
      },
      {
        name: 'nav.cart',
        href: RouteItem.CHECKOUT,
        hideInMenu: true,
      },
      {
        name: 'nav.gratitude',
        href: RouteItem.GRATITUDE,
        buildInIcon: 'favorite',
      },
    ],
    homePage: RouteItem.PRODUCTS,
  },
  {
    name: 'pressman',
    permissions: ['pressman'],
    pages: [
      {
        name: 'nav.print',
        href: RouteItem.PRINT_TASKS,
        icon: 'print-tasks',
      },
      {
        name: 'nav.my-tasks',
        href: RouteItem.MY_TASKS,
        icon: 'my-tasks',
      },
      {
        name: 'nav.filament',
        href: RouteItem.FILAMENT,
        icon: 'filament',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.production',
        href: RouteItem.PRODUCTION,
        icon: 'production',
      },
      {
        name: 'nav.printers',
        href: RouteItem.PRINTERS,
        hideInMenu: true,
      },
    ],
    homePage: RouteItem.PRINT_TASKS,
  },
  {
    name: 'veteran',
    permissions: ['veteran'],
    pages: [
      {
        name: 'nav.print',
        href: RouteItem.PRINT_TASKS,
        icon: 'print-tasks',
      },
      {
        name: 'nav.my-tasks',
        href: RouteItem.MY_TASKS,
        icon: 'my-tasks',
      },
      {
        name: 'nav.filament',
        href: RouteItem.FILAMENT,
        icon: 'filament',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.production',
        href: RouteItem.PRODUCTION,
        icon: 'production',
      },
      {
        name: 'nav.printers',
        href: RouteItem.PRINTERS,
        hideInMenu: true,
      },
    ],
    homePage: RouteItem.PRINT_TASKS,
  },
  {
    name: 'curator',
    permissions: ['curator'],
    pages: [
      {
        name: 'nav.products_management',
        href: RouteItem.PRODUCT_MANAGEMENT,
        icon: 'products-management',
      },
      {
        name: 'nav.requests',
        href: RouteItem.REQUESTS,
        icon: 'requests',
      },
      {
        name: 'nav.customer-orders',
        href: RouteItem.CUSTOMER_ORDERS,
        icon: 'military-orders',
      },
      {
        name: 'nav.fulfillment-report',
        href: RouteItem.FILFILLMENT_REPORTS,
        icon: 'reports',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.quality-check',
        href: RouteItem.QUALITY_CHECK,
        icon: 'quality-check',
      },
      {
        name: 'nav.discussions',
        href: RouteItem.DISCUSSIONS,
        icon: 'discussions',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'uber_curator',
    permissions: ['uber_curator'],
    pages: [
      {
        name: 'nav.statistics',
        href: RouteItem.STATISTICS,
        icon: 'statistic',
      },
      {
        name: 'nav.discussions',
        href: RouteItem.DISCUSSIONS,
        icon: 'discussions',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'assistant',
    permissions: ['assistant'],
    pages: [
      {
        name: 'nav.statistics',
        href: RouteItem.STATISTICS,
        icon: 'statistic',
      },
      {
        name: 'nav.discussions',
        href: RouteItem.DISCUSSIONS,
        icon: 'discussions',
      },
      {
        name: 'nav.review-customers',
        href: RouteItem.REVIEW_CUSTOMERS,
        icon: 'verification',
      },
      {
        name: 'nav.customer-orders',
        href: RouteItem.CUSTOMER_ORDERS,
        icon: 'military-orders',
      },
      {
        name: 'nav.hubs',
        href: RouteItem.HUBS,
        buildInIcon: 'hub',
      },
      {
        name: 'nav.financial-verification',
        href: RouteItem.FINANCIAL_VERIFICATION,
        buildInIcon: 'euro',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'fond_curator',
    permissions: ['fond_curator'],
    pages: [
      {
        name: 'nav.statistics',
        href: RouteItem.STATISTICS,
        icon: 'statistic',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
  {
    name: 'new_customer',
    permissions: ['new_customer'],
    pages: [
      {
        name: 'nav.verification',
        href: RouteItem.VERIFICATION,
      },
    ],
    homePage: RouteItem.VERIFICATION,
  },
  {
    name: 'new_veteran',
    permissions: ['new_veteran'],
    pages: [
      {
        name: 'nav.verification',
        href: RouteItem.VERIFICATION,
      },
    ],
    homePage: RouteItem.VERIFICATION,
  },
  {
    name: 'new_pressman',
    permissions: ['new_pressman'],
    pages: [
      {
        name: 'nav.print',
        href: RouteItem.PRINT_TASKS,
        icon: 'print-tasks',
      },
      {
        name: 'nav.my-tasks',
        href: RouteItem.MY_TASKS,
        icon: 'my-tasks',
      },
      {
        name: 'nav.filament',
        href: RouteItem.FILAMENT,
        icon: 'filament',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
      {
        name: 'nav.production',
        href: RouteItem.PRODUCTION,
        icon: 'production',
      },
      {
        name: 'nav.printers',
        href: RouteItem.PRINTERS,
        hideInMenu: true,
      },
    ],
    homePage: RouteItem.PRINT_TASKS,
  },
  {
    name: 'developer',
    permissions: ['developer'],
    pages: [
      {
        name: 'nav.products',
        href: RouteItem.PRODUCTS,
        icon: 'products',
      },
      {
        name: 'nav.orders',
        href: RouteItem.ORDERS,
        icon: 'orders',
      },
      {
        name: 'nav.custom_order',
        href: RouteItem.CUSTOM_ORDER,
        icon: 'custom-order',
      },
      {
        name: 'nav.school',
        href: RouteItem.SCHOOL,
        icon: 'school',
      },
    ],
    homePage: RouteItem.PRODUCTS,
  },
  {
    name: 'observer',
    permissions: ['observer'],
    pages: [
      {
        name: 'nav.products',
        href: RouteItem.PRODUCTS,
        icon: 'products',
      },
    ],
    homePage: RouteItem.PRODUCTS,
  },
  {
    name: 'stl_access',
    permissions: ['stl_access'],
    pages: [
      {
        name: 'nav.filament',
        href: RouteItem.FILAMENT,
        icon: 'filament',
      },
    ],
    homePage: RouteItem.FILAMENT,
  },
  {
    name: 'hub',
    permissions: ['hub'],
    pages: [
      {
        name: 'nav.hubs',
        href: RouteItem.HUBS,
        buildInIcon: 'hub',
      },
    ],
    homePage: RouteItem.HUBS,
  },
  {
    name: 'financial_verifier',
    permissions: ['financial_verifier'],
    pages: [
      {
        name: 'nav.financial-verification',
        href: RouteItem.FINANCIAL_VERIFICATION,
        buildInIcon: 'euro',
      },
    ],
    homePage: RouteItem.PRODUCT_MANAGEMENT,
  },
];
