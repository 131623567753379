<div class="dnd">
  <div class="dnd-container" appDnd (fileDropped)="onFileDropped($event)">
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      (change)="fileBrowseHandler($event)"
      [accept]="mimeTypes"
    />

    <label for="fileDropRef">
      <mat-icon style="width: 24px; height: 24px">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z"
            fill="#525A67"
          />
        </svg>
      </mat-icon>
      {{ labelKey | translate }}
    </label>
  </div>

  <div class="files-list">
    <div class="single-file" *ngFor="let item of items$ | async; let i = index">
      <div *ngIf="!!item.preview" class="filePreview">
        <div *ngIf="item.preview == '-'; else preview" class="mock">
          <mat-icon>sync</mat-icon>
        </div>
        <ng-template #preview>
          <img [src]="item.preview" />
        </ng-template>
      </div>
      <div class="info">
        <h4 class="name">
          {{ item.file.name }}
        </h4>
        <p class="size">
          {{ formatBytes(item.file.size || 0) }}
        </p>
        <!--app-dnd-progress [progress]="file?.progress"></app-dnd-progress-->
      </div>

      <div class="delete" (click)="deleteFile(i)">
        <mat-icon>delete</mat-icon>
      </div>
    </div>
  </div>
</div>
