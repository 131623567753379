@if (fullName()) {
  <mat-form-field>
    <mat-label>{{ 'lang' | translate }}</mat-label>
    <mat-select [value]="locale.code" (selectionChange)="changeSiteLanguage($event.value)">
      @for (lang of languageList; track lang) {
        <mat-option [value]="lang.code">
          <div class="lang">
            <img
              [src]="'/assets/images/icons/flags/' + lang.code + '.svg' | lowercase"
              [alt]="lang.code"
              width="20"
              height="20"
            />
            {{ lang.label }}
          </div>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
} @else {
  <button mat-icon-button [matMenuTriggerFor]="localeMenu">
    {{ locale.title }}
  </button>
  <mat-menu #localeMenu="matMenu">
    <button mat-menu-item *ngFor="let langData of languageList" (click)="changeSiteLanguage(langData.code)">
      <mat-icon>
        <img
          [src]="'/assets/images/icons/flags/' + langData.code + '.svg' | lowercase"
          [alt]="langData.code"
          width="20"
          height="20"
        />
      </mat-icon>
      <span>{{ langData.label }}</span>
    </button>
  </mat-menu>
}
