import {QuillConfigModule} from 'ngx-quill/config';
import {NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from '@core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from '@core/guards/auth.guard';
import {AuthInterceptor} from '@core/interceptors/auth.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {PageNotFoundComponent} from '@app/pages/page-not-found/page-not-found.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import {SocialLoginModule, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {GoogleLoginProvider} from '@abacritt/angularx-social-login';
import {AuthService} from '@services/auth.service';
import {DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {CookieModule} from 'ngx-cookie';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {BdcWalkModule} from 'bdc-walkthrough';
import {MatIconRegistry} from '@angular/material/icon';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, MaintenanceComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    MatNativeDateModule,
    AppRoutingModule,
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,

        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{list: 'ordered'}, {list: 'bullet'}],
          [{header: [1, 2, 3, 4, false]}],
          [{color: []}, {background: []}],
          ['link'],
          ['clean'],
          ['code-block'],
        ],
      },
    }),
    SocialLoginModule,
    CookieModule.withOptions(),
    BdcWalkModule,
  ],
  exports: [],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '627057169439-s94ec1cltjjkh4lbr8189qfeepo4vp5r.apps.googleusercontent.com',
              {
                oneTapEnabled: false,
              },
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: 'dd.MM.yyyy',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private i18n: TranslateService,
    private adapter: DateAdapter<Date>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.i18n.onLangChange.subscribe(({lang}: LangChangeEvent) => {
      this.adapter.setLocale(lang);
    });

    this.matIconRegistry.addSvgIcon(
      'whatsapp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'signal',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/signal.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'telegram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/telegram.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sapper',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sapper.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sapper-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sapper-filled.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'bpla-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bpla-filled.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'medic-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/medic-filled.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'instructor',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/instructor.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'instructor-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/instructor-filled.svg'),
    );
  }
}
